@import "./reset";
@import "./vars";

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

html,
body {
  height: 100vh;
  background: var(--color-white);
  color: var(--color-black);
  font-family: sans-serif;
  font-size: 20px;
  overflow: hidden;
}

textarea,
input {
  font-size: 20px;
  font-family: sans-serif;
}

main {
  margin-bottom: 40px;

  @media (max-width: 705px) {
    margin-top: 100px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

h1 {
  font-size: 48px;
}

p {
  font-size: inherit;
}

.container {
  max-width: 1220px;
  padding: 0 10px;
  margin: 0 auto;
}

.App {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

// Pagination
.page__container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 40px;
}

.page__link,
.page__next,
.page__prev {
  padding: 10px;
  cursor: pointer;
}

.page__link-active {
  border-bottom: 5px solid var(--color-yellow);
}
