.production__wrapper {
  display: flex;
  flex-direction: column;
  gap: 60px;
  margin-top: 20px;

  h1 {
    text-align: center;

    @media (max-width: 385px) {
      font-size: 32px;
    }
  }

  @media (max-width: 385px) {
    gap: 20px;
  }
}

.stocks__list {
  display: flex;
  flex-wrap: wrap;
  gap: 90px;

  @media (max-width: 1220px) {
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 385px) {
    margin-top: 40px;
  }
}

.stocks__list > a {
  width: 224px;
  height: 224px;

  border: 1px solid var(--color-black);
  background: var(--color-gray);
  cursor: pointer;
  transition: all 0.3s ease;

  h4 {
    padding: 10px;
    font-weight: 400;
    color: var(--color-black);
    font-size: 17px;
  }

  &:hover {
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
  }
}

.stocks__list > a > h4 {
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--color-black);
}

.stocks__list > a > ul > li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.production__villages {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;

  button,
  a {
    background: none;
    font-size: 32px;
    color: var(--color-black);

    @media (max-width: 385px) {
      font-size: 24px;
    }
  }

  a {
    background: var(--color-gray);
    border-radius: 10px;
    padding: 5px 10px;
  }

  @media (max-width: 796px) {
    flex-direction: column;
  }
}
