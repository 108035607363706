body {
  overflow-x: auto !important;
}

.createshipment__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  gap: 20px;

  h1 {
    @media (max-width: 705px) {
      text-align: right;
    }

    @media (max-width: 523px) {
      font-size: 32px;
    }
  }

  div {
    h2 {
      margin-top: 20px;
    }
  }

  @media (max-width: 705px) {
    margin-bottom: 50px;
  }
}

.createshipment__head > img {
  width: 186px;
  height: 192px;
  object-fit: cover;

  @media (max-width: 705px) {
    display: none;
  }
}

.createshipment__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 20px;

  div {
    display: flex;
    align-items: center;
    gap: 40px;

    label {
      width: 200px;
    }

    input {
      border: 1px solid var(--color-gray);
      padding: 5px;
      width: 150px;
    }

    p {
      width: 200px;
    }

    span {
      font-style: italic;
      font-weight: 200;
      opacity: 0.8;
      margin-left: 40px;
    }

    &:nth-child(2) {
      input {
        width: 300px;

        @media (max-width: 344px) {
          width: 100%;
        }
      }
    }

    &:not(:nth-child(3), :nth-child(4), :nth-child(5)) {
      @media (max-width: 600px) {
        flex-direction: column;
        justify-content: start;
        align-items: start;
        gap: 10px;
      }
    }

    &:nth-child(3) {
      flex-wrap: wrap;
      gap: 20px;
    }

    &:nth-child(4) {
      flex-wrap: wrap;
      gap: 20px;
    }

    &:nth-child(5) {
      flex-wrap: wrap;
      gap: 20px;
    }
  }

  button {
    padding: 10px 25px;
    background: var(--color-gray);
    border-radius: 10px;
    margin-top: 20px;
  }

  @media (max-width: 600px) {
    gap: 40px;
  }
}

.createshipment__sum {
  input {
    background: #c0d3ff;
  }
}

.shipmenthistory__wrapper {
  margin-bottom: 40px;
}

table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  tr {
    td {
      p {
        display: none;

        @media (max-width: 600px) {
          display: block;
        }
      }
    }
  }

  th {
    background-color: #f2f2f2;
    font-weight: bold;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr:hover {
    background-color: #ddd;
  }
}

@media screen and (max-width: 600px) {
  thead,
  tbody,
  td,
  tr {
    display: block;
  }

  table {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  th {
    display: none;
  }

  thead tr {
    display: none;
  }

  tr {
    margin-bottom: 10px;
    border: 10px solid #000;
    background: #eaeaea;
  }

  td {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border: none;
    border-bottom: 1px solid #ddd;
    position: relative;
    padding-left: 50%;
    text-align: left;
  }

  td::before {
    content: attr(data-label);
    position: absolute;
    left: 10px;
    font-weight: bold;
    text-transform: uppercase;
  }

  td:last-child {
    border-bottom: 0;
  }
}

.shipmenthistory__villages {
  display: flex;
  gap: 40px;
  margin-bottom: 40px;

  button {
    font-size: 48px;
    background: none;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 20px;
  }
}
