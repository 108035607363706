.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100vh;
}

.login__wrapper {
  img {
    margin-bottom: 20px;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    input {
      padding: 10px;
      border: 1px solid var(--color-gray);
    }

    input[type="submit"] {
      border-radius: 10px;
      border: none;
      transition: opacity 0.2s ease;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
